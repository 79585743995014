<template>
  <div class="download-form-container">
<!--    <error :errors="downloadError" />-->
    <b-overlay :show="formBusy">
      <portlet
        :title="portletTitle"
      >
        <template v-slot:body>
          <b-form ref="download" @submit.stop.prevent="onSubmit" :model="formData" @reset="onReset">
            <b-row>
              <b-col md="3" sm="6" >
                <b-form-group id="input-group-title" label="ھۆججەت نامى" label-for="alias" >
                  <b-form-input id="alias" v-model="formData.alias" :state="validateState('alias')" type="text" aria-describedby="alias-live-feedback" placeholder="ھۆججەت نامىنى كىرگۈزۈڭ" />
                  <b-form-invalid-feedback id="alias-live-feedback">
                    باشقىلارنىڭ كۆرۈپ چۈشىنىشى ئۈچۈن، ھۆججەت نامىنى كىرگۈزۈڭ
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="3" sm="6" >
                <b-form-group :state="validateState('file_type')" aria-describedby="file_type-live-feedback" label="يوللايدىغان ھۆججەت تىپىنى تاللاڭ" v-slot="{ ariaDescribedby }">
                  <b-form-radio-group
                    id="btn-radios-1"
                    v-model="formData.file_type"
                    :options="options"
                    :aria-describedby="ariaDescribedby"
                    name="radios-btn-default"
                    buttons
                  ></b-form-radio-group>
                  <b-form-invalid-feedback id="file_type-live-feedback">
                    ھۆججەت تىپىنى تاللاڭ
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12" md="3">
                <b-form-group :state="validateState('url')" aria-describedby="target-live-feedback" id="input-group-url" label="ھۆججەت تاللاڭ" label-for="url">
                  <image-uploader btn-label="ھۆججەت تاللاڭ" v-model="formData.url" :accepted-file-types="accept" :upload-form="{strategy: 'download_file'}" />
                  <b-form-invalid-feedback id="target-live-feedback">
                    ھۆججەت تاللاڭ
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <b-button id="submit" type="submit" variant="primary">
              ساقلاش
            </b-button>
            <b-button class="ml-2" type="reset" variant="danger">
              بىكار قىلىش
            </b-button>
          </b-form>
        </template>
      </portlet>
    </b-overlay>
  </div>
</template>

<script>
  import Portlet from "../../partials/content/Portlet";
  import Error from "../../partials/widgets/Error";
  import ImageUploader from "../../partials/layout/ImageUploader";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import articleDownloadMixin from "../../../mixins/articleDownload.mixin";
  import validateStateMixin from "../../../mixins/common/validateStateMixin";
  import { validationMixin } from "vuelidate";
  import { required, requiredIf } from "vuelidate/lib/validators";
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";
  import { mapGetters } from "vuex";
  export default {
    name: "DownloadForm",
    mixins: [ formBusyMixin, validateStateMixin, validationMixin, articleDownloadMixin, sweetAlertMixin ],
    components: { Portlet, Error, ImageUploader },
    props: {
      action: {
        type: String,
        default: 'create'
      }
    },
    validations(){
      return {
        formData: {
          alias: { required },
          url: { required },
          file_type: { required },
        },
      }
    },
    computed: {
      ...mapGetters([]),
      portletTitle(){
        if ( this.action === 'create' )
          return 'يېڭىدىن ھۆججەت قوشۇش';
        else if ( this.action === 'edit' )
          return 'ھۆججەت ئۇچۇرىنى تەھرىرلەش';
      },
      accept(){
        let accepts = {
          pdf: "application/pdf",
          word: "application/msword",
          excel: "application/vnd.ms-excel",
        };
        return accepts[this.formData.file_type];
      },
      aid(){
        return this.$route.params.aid;
      }
    },
    mounted() {},
    created() {
      if (this.action === 'edit'){
      }
    },
    data(){
      return {
        formData: {
          file_type: 'pdf',
          url: null,
          alias: null,
        },
        formBusy: false,
        targetTitle: '',
        accepts: ['application/msword', 'application/pdf', 'application/vnd.ms-excel'],
        options: [
          { html: `<div class="d-flex flex-column justify-content-center align-content-center">
<img src="assets/media/files/pdf.svg" class="img-fluid" style="height: 50px;" />
<span class="mt-2 kt-font-dark"> PDF ھۆججىتى </span>
</div>`, value: 'pdf' },
          { html: `<div class="d-flex flex-column justify-content-center align-content-center">
<img src="assets/media/files/doc.svg" class="img-fluid" style="height: 50px;" />
<span class="mt-2 kt-font-dark"> WORD ھۆججىتى </span>
</div>`, value: 'word' },
          { html: `<div class="d-flex flex-column justify-content-center align-content-center">
<img src="assets/media/files/csv.svg" class="img-fluid" style="height: 50px;" />
<span class="mt-2 kt-font-dark"> EXCEL ھۆججىتى </span>
</div>`, value: 'excel' },
        ]
      }
    },
    methods: {
      onSubmit(){
        this.$v.formData.$touch();
        if (this.$v.formData.$anyError) {
          return this.warning('تولدۇرۇشقا تېگىشلىك جەدىۋەلنى تۇلۇق تولدۇرۇڭ');
        }
        if (this.action === 'create'){
          this.storeArticleDownload(this.aid, this.formData)
                  .then(()=>{
                    return this.$router.replace({name: "articles.download.home", params: {aid: this.aid}});
                  })
        }else if ( this.action === 'edit' ){
        }
      },
      onReset(){
        return this.$router.replace({ name: "articles.download.home", params: {aid: this.aid} });
      },
    }
  }
</script>
